import HttpFactory from '../../factory'
import { type IExtrasFetch } from '~/repository/interfaces/IExtrasFetch'
import removeEmpty from '~/helpers/helpers'
import { type ISearchableProductCargoTypeFields } from '~/repository/modules/productCargoType/types/productCargoTypeTypes'
import { PRODUCT_CARGO_TYPES } from '~/constants/apiPaths/productCargoTypes/paths'
import { type IProductCargoTypePayload } from '~/store/productCargoTypes/types/productCargoTypeTypes'

class ProductCargoTypesModule extends HttpFactory {
  async getAll(extras: IExtrasFetch, searchCriteria: ISearchableProductCargoTypeFields, signal?: Nullable<AbortSignal>): Promise<object> {
    const urlParams = removeEmpty(searchCriteria as {})
    return await this.call<object>('GET', `${this.hostname}${PRODUCT_CARGO_TYPES}?${new URLSearchParams(urlParams)}`, null, extras, signal)
  }

  async create(productCargoType: IProductCargoTypePayload, extras: IExtrasFetch): Promise<object> {
    // @ts-ignore
    delete productCargoType.Id

    return await this.call<object>('POST', `${this.hostname}${PRODUCT_CARGO_TYPES}`, productCargoType, extras)
  }

  async update(productCargoType: IProductCargoTypePayload, extras: IExtrasFetch): Promise<object> {
    return await this.call<object>('PUT', `${this.hostname}${PRODUCT_CARGO_TYPES}`, productCargoType, extras)
  }

  async delete(extras: IExtrasFetch, productCargoTypeId: number): Promise<object> {
    return await this.call<object>(
      'DELETE',
      `${this.hostname}${PRODUCT_CARGO_TYPES}`,
      {
        Id: productCargoTypeId,
      },
      extras,
    )
  }
}

export default ProductCargoTypesModule
