
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexooJswcUi1vMeta } from "/app/pages/brands/index.vue?macro=true";
import { default as indexoLN6DBkdqCMeta } from "/app/pages/companies/index.vue?macro=true";
import { default as customer_45mapkPmCZmmry0Meta } from "/app/pages/customer-map.vue?macro=true";
import { default as companiesQfjTIdLHogMeta } from "/app/pages/customers/[id]/companies.vue?macro=true";
import { default as _91id_93BQUKze2ZEUMeta } from "/app/pages/customers/companies/[id].vue?macro=true";
import { default as indexBmGpQ1GKXXMeta } from "/app/pages/customers/companies/index.vue?macro=true";
import { default as indexFXjYqPb3WZMeta } from "/app/pages/customers/index.vue?macro=true";
import { default as indexhg2nWJrbRRMeta } from "/app/pages/dashboard/index.vue?macro=true";
import { default as forgot_45password1UbT4qDpFeMeta } from "/app/pages/forgot-password.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexLQi9yWWIHVMeta } from "/app/pages/invoices/[id]/index.vue?macro=true";
import { default as paymentsa6Ct9dFzv7Meta } from "/app/pages/invoices/[id]/payments.vue?macro=true";
import { default as indexjq7Ho9BMUYMeta } from "/app/pages/invoices/index.vue?macro=true";
import { default as detailsND4OG1fXmlMeta } from "/app/pages/invoices/installmentPayments/[id]/details.vue?macro=true";
import { default as indext3x1ktatguMeta } from "/app/pages/invoices/installmentPayments/index.vue?macro=true";
import { default as indexKZPsHyO1kCMeta } from "/app/pages/invoices/payments/index.vue?macro=true";
import { default as indexgK5VicK7y2Meta } from "/app/pages/login/index.vue?macro=true";
import { default as valuessfsFzllNLFMeta } from "/app/pages/products/attributes/[id]/values.vue?macro=true";
import { default as indexSbGe1nE9cWMeta } from "/app/pages/products/attributes/index.vue?macro=true";
import { default as subcategories0kw5QwfyllMeta } from "/app/pages/products/categories/[id]/subcategories.vue?macro=true";
import { default as indexB6fm9NU8mzMeta } from "/app/pages/products/categories/index.vue?macro=true";
import { default as index18IXo8oN8QMeta } from "/app/pages/products/index.vue?macro=true";
import { default as indexcYLHwCDNydMeta } from "/app/pages/reports/index.vue?macro=true";
import { default as indexnq54o81oozMeta } from "/app/pages/users/index.vue?macro=true";
import { default as index56OPAUTsqnMeta } from "/app/pages/warehouses/index.vue?macro=true";
export default [
  {
    name: "brands",
    path: "/brands",
    meta: indexooJswcUi1vMeta || {},
    component: () => import("/app/pages/brands/index.vue")
  },
  {
    name: "companies",
    path: "/companies",
    meta: indexoLN6DBkdqCMeta || {},
    component: () => import("/app/pages/companies/index.vue")
  },
  {
    name: "customer-map",
    path: "/customer-map",
    meta: customer_45mapkPmCZmmry0Meta || {},
    component: () => import("/app/pages/customer-map.vue")
  },
  {
    name: "customers-id-companies",
    path: "/customers/:id()/companies",
    meta: companiesQfjTIdLHogMeta || {},
    component: () => import("/app/pages/customers/[id]/companies.vue")
  },
  {
    name: "customers-companies-id",
    path: "/customers/companies/:id()",
    meta: _91id_93BQUKze2ZEUMeta || {},
    component: () => import("/app/pages/customers/companies/[id].vue")
  },
  {
    name: "customers-companies",
    path: "/customers/companies",
    meta: indexBmGpQ1GKXXMeta || {},
    component: () => import("/app/pages/customers/companies/index.vue")
  },
  {
    name: "customers",
    path: "/customers",
    meta: indexFXjYqPb3WZMeta || {},
    component: () => import("/app/pages/customers/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexhg2nWJrbRRMeta || {},
    component: () => import("/app/pages/dashboard/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45password1UbT4qDpFeMeta || {},
    component: () => import("/app/pages/forgot-password.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "invoices-id",
    path: "/invoices/:id()",
    meta: indexLQi9yWWIHVMeta || {},
    component: () => import("/app/pages/invoices/[id]/index.vue")
  },
  {
    name: "invoices-id-payments",
    path: "/invoices/:id()/payments",
    meta: paymentsa6Ct9dFzv7Meta || {},
    component: () => import("/app/pages/invoices/[id]/payments.vue")
  },
  {
    name: "invoices",
    path: "/invoices",
    meta: indexjq7Ho9BMUYMeta || {},
    component: () => import("/app/pages/invoices/index.vue")
  },
  {
    name: "invoices-installmentPayments-id-details",
    path: "/invoices/installmentPayments/:id()/details",
    meta: detailsND4OG1fXmlMeta || {},
    component: () => import("/app/pages/invoices/installmentPayments/[id]/details.vue")
  },
  {
    name: "invoices-installmentPayments",
    path: "/invoices/installmentPayments",
    meta: indext3x1ktatguMeta || {},
    component: () => import("/app/pages/invoices/installmentPayments/index.vue")
  },
  {
    name: "invoices-payments",
    path: "/invoices/payments",
    meta: indexKZPsHyO1kCMeta || {},
    component: () => import("/app/pages/invoices/payments/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexgK5VicK7y2Meta || {},
    component: () => import("/app/pages/login/index.vue")
  },
  {
    name: "products-attributes-id-values",
    path: "/products/attributes/:id()/values",
    meta: valuessfsFzllNLFMeta || {},
    component: () => import("/app/pages/products/attributes/[id]/values.vue")
  },
  {
    name: "products-attributes",
    path: "/products/attributes",
    meta: indexSbGe1nE9cWMeta || {},
    component: () => import("/app/pages/products/attributes/index.vue")
  },
  {
    name: "products-categories-id-subcategories",
    path: "/products/categories/:id()/subcategories",
    meta: subcategories0kw5QwfyllMeta || {},
    component: () => import("/app/pages/products/categories/[id]/subcategories.vue")
  },
  {
    name: "products-categories",
    path: "/products/categories",
    meta: indexB6fm9NU8mzMeta || {},
    component: () => import("/app/pages/products/categories/index.vue")
  },
  {
    name: "products",
    path: "/products",
    meta: index18IXo8oN8QMeta || {},
    component: () => import("/app/pages/products/index.vue")
  },
  {
    name: "reports",
    path: "/reports",
    meta: indexcYLHwCDNydMeta || {},
    component: () => import("/app/pages/reports/index.vue")
  },
  {
    name: "users",
    path: "/users",
    meta: indexnq54o81oozMeta || {},
    component: () => import("/app/pages/users/index.vue")
  },
  {
    name: "warehouses",
    path: "/warehouses",
    meta: index56OPAUTsqnMeta || {},
    component: () => import("/app/pages/warehouses/index.vue")
  }
]