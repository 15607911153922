import { defineNuxtPlugin } from '#app'
import VueGoogleMaps from '@fawmi/vue-google-maps'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyAOub6pfARq6Tr-Rc00Y9Ua9fpZnrEjnlE',
      libraries: 'places', // This is required if you use the Autocomplete plugin
    },
  })
})
